import { type FunctionComponent } from 'react';
import {
	SHOWROOM_MEET_EXPERT_CARD_COPY,
	SHOWROOM_MEET_EXPERT_CARD_TITLE,
	SHOWROOM_MEET_EXPERT_LOCATION,
	SHOWROOM_MEET_EXPERT_NAME,
	SHOWROOM_MEET_EXPERT_PHOTO_PUBLIC_ID
} from '../../../constants/showroom/general';
import { useIsBuildDomain } from '../../../hooks/apollo/site/site.hooks';
import { StyledButton } from '../../buttons';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';
import { ShowroomSearchTextInput } from '../showroom-search-text-input/showroom-search-text-input.component';

const ShowroomMeetExpertPersonDetails: FunctionComponent = () => {
	return (
		<div className="dn flex-l flex-column items-end nb7 nr5">
			<p className="theme-grey-darker omni-theme-primary b fw7 f7 ma0 lh-copy">{SHOWROOM_MEET_EXPERT_NAME}</p>
			<p className="theme-grey-darker omni-theme-primary fw3 f7 ma0 lh-copy">{SHOWROOM_MEET_EXPERT_LOCATION}</p>
		</div>
	);
};

const ShowroomMeetExpertCardDetails: FunctionComponent = () => (
	<div className="flex flex-column mw6-m">
		<div className="db dn-ns" style={{ height: 210 }}></div>
		<h2 className="theme-grey-darker omni-theme-primary ma0 fw7 f3 f2-ns ma0 mb1 lh-title">{SHOWROOM_MEET_EXPERT_CARD_TITLE}</h2>
		<p className="theme-grey-dark fw4 f6 f4-ns lh-copy ma0 mb3">{SHOWROOM_MEET_EXPERT_CARD_COPY}</p>
	</div>
);

const ShowroomMeetExpertPhotoSectionMobile: FunctionComponent = () => (
	<div className="db dn-ns absolute top--2 w-100">
		<div className="flex flex-column items-center w-100">
			<CloudinaryImage
				publicID={SHOWROOM_MEET_EXPERT_PHOTO_PUBLIC_ID}
				options={{ width: 240, height: 240, format: 'png', crop: 'lpad' }}
				description="expert photo"
				className="z-1"
			/>
			<div className="bw1 bb b--theme-primary-light absolute" style={{ width: '88%', bottom: 0.5 }} />
		</div>
	</div>
);

const ShowroomMeetExpertPhotoSectionDesktop: FunctionComponent = () => (
	<div className="dn db-ns absolute bottom-0 right-1">
		<div className="flex items-center">
			<ShowroomMeetExpertPersonDetails />
			<CloudinaryImage
				publicID={SHOWROOM_MEET_EXPERT_PHOTO_PUBLIC_ID}
				options={{ width: 240, height: 240, format: 'png', crop: 'lpad' }}
				description="expert photo"
			/>
		</div>
	</div>
);

const ShowroomMeetExpertButtonAction: FunctionComponent<{ buttonOnClick?: () => void }> = ({ buttonOnClick }) => {
	return (
		<>
			<div className="mb2" style={{ maxWidth: 302 }}>
				<StyledButton onClick={buttonOnClick}>Book Your Appointment</StyledButton>
			</div>
			<p className="theme-grey-dark i fw4 f7 ma0">Appointments recommended.</p>
		</>
	);
};

export type ShowroomMeetExpertProps = {
	searchOnClick?: (keyword: string) => void;
	buttonOnClick?: () => void;
	backgroundColor?: 'white' | 'primary-lighter';
	action?: 'input' | 'button';
};

export const ShowroomMeetExpert: FunctionComponent<ShowroomMeetExpertProps> = ({
	searchOnClick,
	buttonOnClick,
	backgroundColor = 'white',
	action = 'input'
}) => {
	const isBuild = useIsBuildDomain();
	const determinedBackground =
		isBuild && backgroundColor === 'primary-lighter'
			? 'grey-lighter'
			: isBuild && backgroundColor === 'white'
			? 'white'
			: backgroundColor;

	return (
		<section className="relative flex flex-column flex-row-ns items-center">
			{/* Desktop */}
			<ShowroomMeetExpertPhotoSectionDesktop />
			{/* Mobile */}
			<ShowroomMeetExpertPhotoSectionMobile />
			<div className={`bg-theme-${determinedBackground} w-100 br2 pa4`} data-testid="showroom-meet-expert-card">
				<ShowroomMeetExpertCardDetails />
				<div className="w-100 w-60-m">
					{action === 'input' && searchOnClick ? (
						<ShowroomSearchTextInput searchOnClick={searchOnClick} />
					) : (
						<ShowroomMeetExpertButtonAction buttonOnClick={buttonOnClick} />
					)}
				</div>
			</div>
		</section>
	);
};
