import { type ImageFieldsFragment } from '../../__generated__/graphql-client-types';

export const SHORT_TO_FULL_DAY_NAME = {
	mon: 'monday',
	tue: 'tuesday',
	wed: 'wednesday',
	thu: 'thursday',
	fri: 'friday',
	sat: 'saturday',
	sun: 'sunday'
};

// Showroom Meet with an Expert CTA
export const SHOWROOM_MEET_EXPERT_PHOTO_PUBLIC_ID = 'siteassets/showrooms/e3d6d482093cc87236dc05f9cbc684f5.png';
export const SHOWROOM_MEET_EXPERT_NAME = 'Sasha Brooks';
export const SHOWROOM_MEET_EXPERT_LOCATION = 'San Francisco, CA Showroom';
export const SHOWROOM_MEET_EXPERT_CARD_TITLE = 'Meet with an Expert Consultant Today';
export const SHOWROOM_MEET_EXPERT_CARD_COPY = 'Explore the most innovative products with a Ferguson Home project expert.';

// Showroom Experience
export const SHOWROOM_EXPERIENCE_TITLE = 'The Showroom Experience';
export const SHOWROOM_EXPERIENCE_DESCRIPTION =
	"Whether you're a homeowner, builder, designer, or architect, you'll find inspiration and expertise at every step. Schedule an appointment at your Ferguson Home showroom and discover everything you need to bring your vision to life.";

type ExperienceData = {
	title: string;
	description: string;
	imageId: string;
	imagePosition: 'LEFT' | 'RIGHT';
};

export const SHOWROOM_EXPEIRENCE_CARD_DATA: ExperienceData[] = [
	{
		title: 'Consult with Experts',
		description:
			'When you book a one-on-one consultation at your local showroom, our experts become your project partners. Work with them to mix and match the products that will maximize the style and functionality of your design.',
		imagePosition: 'LEFT',
		imageId: 'siteassets/showrooms/Ferguson-Home_Experience_Expert-Consultant.jpg'
	},
	{
		title: 'Immerse Yourself in Inspiration',
		description:
			"Our nationwide network of showrooms provide an opportunity for you to see, touch, and interact with the products you're choosing for your projects. Discover all the latest, most innovative products by the brands you love, all in a beautifully curated setting. ",
		imagePosition: 'RIGHT',
		imageId: 'siteassets/showrooms/Ferguson-Home_Experience_Inspiration.jpg'
	},
	{
		title: 'Get Exceptional Project Support',
		description:
			'Our project experts are here to support you every step of the way, from helping you choose the products that are the best fit for your design to coordinating delivery.',
		imagePosition: 'LEFT',
		imageId: 'siteassets/showrooms/Ferguson-Home_Experience_Project_Support.jpg'
	}
];

export const SHOWROOM_EXPIERENCE_PREPARE_SECTION_TITLE = 'How to Prepare';
export const SHOWROOM_EXPIERENCE_PREPARE_SECTION_DESCRIPTION =
	'To make the most of your showroom visit, gather or determine the following:';

export const SHOWROOM_EXPIERENCE_PREPARE_SECTION_DATA: Omit<ExperienceData, 'imagePosition'>[] = [
	{
		title: 'Inspiration',
		description:
			'Think about what styles, colors, and products catch your eye. <a href="/shop-by-look" class="theme-primary">Shop by look</a> to view curated projects and save your favorites to share with us during your appointment.',
		imageId: 'siteassets/showrooms/Prepare_Inspiration.jpg'
	},
	{
		title: 'Partners',
		description:
			"Let us know if you're collaborating with other professionals or managing the project solo, so we can tailor our support.",
		imageId: 'siteassets/showrooms/Prepare_Partners.jpg'
	},
	{
		title: 'Details',
		description: 'Bring any plans, blueprints, or measurements. These details help us recommend the best solutions for your project.',
		imageId: 'siteassets/showrooms/Prepare_Details.jpg'
	},
	{
		title: 'Budget',
		description: 'Having a clear budget, goals, and expectations ensures everything stays on track and aligned with your vision.',
		imageId: 'siteassets/showrooms/Prepare_Budget.jpg'
	}
];

export const SHOWROOM_MARBLE_BACKGROUND_PUBLIC_ID = 'siteassets/showrooms/marble-background.jpg';
export const UNIQUE_SELLING_POINT_INPUT_LABEL = 'Find a Showroom Near You';

// Prepare Today With Projects CTA
export const SHOWROOM_PREPARE_TODAY_WITH_PROJECTS_PHOTO_PUBLIC_ID = '/siteassets/showrooms/fh-project-imac.png';

// Showroom Location Page
export const FALLS_CHURCH_STUDIO_BRANCH_PAGE_URL = 'falls-church-va-showroom-1133';
export const NEARBY_PLUMBING_PARAGRAPH =
	'This showroom is co-located with a Ferguson plumbing sales counter, offering residential and commercial plumbing products. Sales counter hours and contact information may differ.';
export const SHOWROOM_LOCATIONS_NEAR_YOU_TITLE = 'Showrooms Near';

export const LANDING_PAGE_IMAGES: ImageFieldsFragment[] = [
	{
		id: 'siteassets/showrooms/Ferguson-Home_Showroom.jpg',
		imageType: 'private',
		description: 'ferguson home showroom store front'
	},
	{
		id: 'siteassets/showrooms/Ferguson-Home_Unique-Shopping-Experience.jpg',
		imageType: 'private',
		description: 'ferguson home showroom interior'
	}
];
