import { type FunctionComponent } from 'react';
import { configureRoute } from '../../../../utils/routes/configure-route';
import { StyledLink } from '../../../components/common-components/link/styled-link.component';
import { PageContainer } from '../../../components/common-components/page/page-container/page-container.component';
import { PrepareTodayWithProjects } from '../../../components/showroom-components/prepare-today-with-projects/prepare-today-with-projects.component';
import { ShowroomExperienceContainer } from '../../../components/showroom-components/showroom-experience/showroom-experience-container/showroom-experience-container.component';
import { ShowroomPageHeader } from '../../../components/showroom-components/showroom-page-header/showroom-page-header.component';
import { ShowroomVideo } from '../../../components/showroom-components/showroom-video/showroom-video.component';
import { ShowroomsNearMeContainer } from '../../../components/showroom-components/showrooms-near-me/showrooms-near-me.component';
import { UniqueSellingPointCta } from '../../../components/showroom-components/unique-selling-point-cta/unique-selling-point-cta.component';
import { MAX_PAGE_WIDTH } from '../../../constants/breakpoints';
import { SHOWROOM_LINK, SHOWROOM_SEARCH_LINK, SSO_LOGIN_LINK, SSO_LOGOUT_LINK } from '../../../constants/links';
import { LANDING_PAGE_IMAGES } from '../../../constants/showroom';
import { useTrackPageView } from '../../../hooks/analytics/analytics.hooks';
import { useCustomer } from '../../../hooks/apollo/customer/customer.hooks';
import { useEmployee } from '../../../hooks/apollo/employee/employee.hooks';
import { useNavigation } from '../../../hooks/navigation/navigation.hooks';

export const ShowroomLanding: FunctionComponent = () => {
	const { employee } = useEmployee();
	const isAuthenticatedEmployee = employee?.isAuthenticated;

	const navigate = useNavigation();

	const { data } = useCustomer();

	const pageName = 'landing_pages:showroom_landing';
	const showroomDataLayer = {
		page: pageName,
		isShowroom: true
	};

	useTrackPageView({ pageName }, showroomDataLayer);

	const location = data?.customer?.location;

	const onLocationSearch = (input: string): void => {
		const searchByLocationLink = `${SHOWROOM_SEARCH_LINK}?searchTerm=${input}`;
		navigate(searchByLocationLink);
	};

	return (
		<section className="flex flex-column justify-center gr3 gr6-ns w-100">
			<div className="bg-theme-grey-lighter w-100 flex justify-center">
				<div className="flex pv3 pt6-ns pb7-ns" style={{ maxWidth: MAX_PAGE_WIDTH }}>
					<ShowroomPageHeader onSuccessSubmit={onLocationSearch} locationImages={LANDING_PAGE_IMAGES} />
				</div>
			</div>
			{location && (
				<div className="center w-100" style={{ maxWidth: MAX_PAGE_WIDTH }}>
					<ShowroomsNearMeContainer zipcode={location.zipCode} city={location.city} />
				</div>
			)}
			<div className="ph3 ph0-l center w-100" style={{ maxWidth: MAX_PAGE_WIDTH }}>
				<ShowroomVideo />
				<div className="mt4">
					<UniqueSellingPointCta searchOnClick={onLocationSearch} />
				</div>
			</div>
			<ShowroomExperienceContainer searchOnClick={onLocationSearch} />
			<div className="ph3 ph0-l mt5 center" style={{ maxWidth: MAX_PAGE_WIDTH }}>
				<PrepareTodayWithProjects />
			</div>
			<div className="ph3 ph0-l">
				<div className="f7 pt3 mb5 bt bw1 b--theme-grey-light center w-100" style={{ maxWidth: 1280 }}>
					<PageContainer
						pageTitle="Ferguson Kitchen & Bath Showroom Locations"
						canonicalURL="/showroom"
						metaDescription="Find your local Ferguson Bath, Kitchen & Lighting Gallery. Our showroom offers premium products for your next renovation or build."
						layout="minimized">
						{isAuthenticatedEmployee ? (
							<>
								{employee.fullName}:
								<StyledLink underline url={SSO_LOGOUT_LINK} className="ml1" color="grey-darker" underlineHover={true}>
									Logout
								</StyledLink>
							</>
						) : (
							<StyledLink url={SSO_LOGIN_LINK} color="grey-darker" underlineHover={true}>
								Employee Login
							</StyledLink>
						)}
					</PageContainer>
				</div>
			</div>
		</section>
	);
};

export const RoutedShowroom = configureRoute({ path: SHOWROOM_LINK, exact: true, DANGEROUSLY_IS_LIVE: true }, ShowroomLanding);
