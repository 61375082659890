import { type VideoFieldsFragment } from '../../__generated__/graphql-client-types';

export const FERGUSON_BATH_KITCHEN_LIGHTING_GALLERY_VIDEO: VideoFieldsFragment = {
	streamProviderCode: 1,
	title: 'Ferguson Bath Kitchen & Lighting Gallery',
	description: 'Ferguson Bath Kitchen & Lighting Gallery',
	id: '0',
	hashKey: '2f96vynljh',
	screenshotId: 0
};

export const FERGUSON_HOME_VIDEO: VideoFieldsFragment = {
	streamProviderCode: 1,
	title: 'Ferguson Home Showroom Experience',
	description: 'Ferguson Home Showroom Experience',
	id: '0',
	hashKey: '7doqqx4ztk',
	screenshotId: 0
};
