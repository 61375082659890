import { type FunctionComponent } from 'react';
import { CloudinaryImage } from '../../../images/cloudinary-image/cloudinary-image.component';
import { cardHeight, descriptionWidth } from './showroom-experience-content-compact-card.css';

export type ShowroomExperienceContentCompactCardProps = {
	title: string;
	description: string;
	imageId: string;
};

export const ShowroomExperienceContentCompactCard: FunctionComponent<ShowroomExperienceContentCompactCardProps> = ({
	title,
	description,
	imageId
}) => {
	return (
		<div className="relative flex flex-column">
			<div className="db dn-ns absolute flex justify-center top-0 pl3">
				<CloudinaryImage
					publicID={imageId}
					options={{ width: 367, height: 185, crop: 'fill' }}
					description="showroom experience"
					className="br2 shadow-1"
				/>
			</div>
			<div className="dn db-ns absolute top-0 left-1 pr3">
				<CloudinaryImage
					publicID={imageId}
					options={{ width: 300, height: 155, crop: 'fill' }}
					description="showroom experience"
					className="br2 shadow-1"
				/>
			</div>

			<div className={`mt5 bg-theme-white br2 gr3 pa3 ${cardHeight}`}>
				<div className="db dn-ns" style={{ height: 148 }} />
				<div className="dn db-ns" style={{ height: 118 }} />
				<h2 className="ma0 pa0 f4 lh-title b theme-grey-darker">{title}</h2>
				<div className="theme-accent bw1 bb mv2" style={{ width: 150 }} />
				{/* One of the hardcoded descriptions has an anchor tag in it */}
				<p
					className={`ma0 pa0 f6 lh-copy fw4 theme-grey-dark ${descriptionWidth}`}
					dangerouslySetInnerHTML={{ __html: description }}></p>
			</div>
		</div>
	);
};
