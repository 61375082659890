import { type FunctionComponent } from 'react';
import { type ImageFieldsFragment } from '../../../__generated__/graphql-client-types';
import { ShowroomHeroImages } from '../showroom-hero-images/showroom-hero-images.component';
import { ShowroomSearchTextInput } from '../showroom-search-text-input/showroom-search-text-input.component';

type ShowroomPageHeaderProps = {
	onSuccessSubmit: (input: string) => void;
	locationImages?: ImageFieldsFragment[];
};

export const ShowroomPageHeader: FunctionComponent<ShowroomPageHeaderProps> = ({ onSuccessSubmit, locationImages }) => {
	return (
		<div className="flex flex-column-reverse flex-row-ns theme-grey-lighter justify-between justify-around-ns">
			<div className="theme-grey-darker omni-theme-primary flex flex-column justify-center theme-primary lh-title f4 pa3 mt5 mt0-ns w-100 w-50-ns">
				<span>Bring Your Visions to Life at</span>
				<span className="f1 b">Ferguson Home</span>
				<p className="f5 lh-copy theme-grey-dark" style={{ maxWidth: 540 }}>
					Our nationwide network of showrooms features beautiful displays of kitchen, bathroom, and lighting products in a
					welcoming, hands-on environment designed to inspire. Our knowledgeable experts provide a collaborative, consultative
					experience from start to finish to help bring your project to life.
				</p>
				<span className="mv3 theme-grey-dark b">Find a Showroom Near You</span>
				<ShowroomSearchTextInput searchOnClick={onSuccessSubmit} />
			</div>
			<div className="flex justify-start justify-center-ns items-center w-100 w-50-ns ph3 ph0-ns">
				<ShowroomHeroImages images={locationImages} />
			</div>
		</div>
	);
};
