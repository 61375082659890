import { type ImageFieldsFragment } from '../../__generated__/graphql-client-types';

export const DEFAULT_SHOWROOM_HERO_IMAGES: ImageFieldsFragment[] = [
	{
		id: 'siteassets/showrooms/pages/location-detail/default-location-hero.jpg',
		description: 'Ferguson Location Hero Image',
		imageType: 'private'
	},
	{
		id: 'siteassets/showrooms/pages/location-detail/default-location-inset.jpg',
		description: 'Ferguson Location Hero Image 2',
		imageType: 'private'
	}
];
