import { useEffect, useState } from 'react';
import { type LocationMarker } from '../../../types/google-maps.types';
import { type ShowroomLocation } from '../../../types/showroom.types';

// From https://stackoverflow.com/a/27943
const deg2rad = (deg: number) => {
	return deg * (Math.PI / 180);
};

const getDistanceFromLatLonInKm = (lat1: number, lon1: number, lat2: number, lon2: number) => {
	const R = 6371; // Radius of the earth in km
	const dLat = deg2rad(lat2 - lat1); // deg2rad below
	const dLon = deg2rad(lon2 - lon1);
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	return R * c; // Distance in km
};

/**
 * Sort showroom locations by distance from the given center. If center is not available (centerLoading=false and
 * center=undefined), then returns given unsorted locations as a backup strategy.
 */
export const useSortedShowroomLocations = (
	center: LocationMarker | undefined,
	locations: ShowroomLocation[] | undefined
): ShowroomLocation[] | undefined => {
	const [sortedLocations, setSortedLocations] = useState<ShowroomLocation[]>();
	useEffect(() => {
		if (!center || !locations) {
			setSortedLocations(undefined);
			return;
		}

		setSortedLocations(
			locations
				.map((location: ShowroomLocation) => {
					// Find distance from the customer to this location.
					const distanceFromCustomer = getDistanceFromLatLonInKm(
						center.latitude,
						center.longitude,
						location.address.latitude,
						location.address.longitude
					);
					return { ...location, distanceFromCustomer };
				})
				.sort((a, b) => a.distanceFromCustomer - b.distanceFromCustomer)
		);
	}, [center, locations]);

	return sortedLocations;
};
