import { type FunctionComponent } from 'react';
import { FERGUSON_HOME_VIDEO } from '../../../constants/showroom';
import { isChromatic } from '../../../helpers/general-helper/general-helper';
import { EmbeddedVideo } from '../../common-components/embedded-video/embedded-video.component';

export const ShowroomVideo: FunctionComponent = () => {
	return (
		<div className="center" data-testid="video-container">
			<EmbeddedVideo video={FERGUSON_HOME_VIDEO} shouldLoadEagerly={isChromatic()} />
		</div>
	);
};
