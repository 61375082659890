import { type FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { StyledButton } from '../../buttons/styled-button/styled-button.component';
import { DynamicTextInput } from '../../inputs/input/input.component';
import { ChevronRightIcon } from '../../svg/icons.component';

export type ShowroomSearchTextInputProps = {
	searchOnClick: (keyword: string) => void;
};

type FormInputs = {
	location: string;
};

export const ShowroomSearchTextInput: FunctionComponent<ShowroomSearchTextInputProps> = ({ searchOnClick }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm<FormInputs>();

	const onSubmit = (formData: FormInputs) => {
		searchOnClick(formData.location);
		reset();
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="flex flex-column"
			aria-label="search showrooms by location"
			data-testid="find-nearby-locations-form">
			<DynamicTextInput
				placeholder="Search by Zip Code, City or State"
				className="w-100 w-auto-ns mw6-ns"
				ariaLabel="search showrooms"
				// needed to activate clickable icon style
				iconOnClick={() => {}}
				padIcon={false}
				inputClassName="f5 f6-ns"
				icon={
					<StyledButton buttonType="submit" testId="submit-location" useBorderRadius={false}>
						<div className="flex items-center pv0 ph2 pv1-ns ph2-ns">
							<ChevronRightIcon className="theme-white pv1 pv0-ns f4 f3-ns" />
						</div>
					</StyledButton>
				}
				invalid={Boolean(errors.location)}
				invalidMessage={errors.location?.message}
				{...register('location')}
			/>
		</form>
	);
};
