import { type FunctionComponent } from 'react';
import { UNIQUE_SELLING_POINT_INPUT_LABEL } from '../../../constants/showroom';
import { ShowroomSearchTextInput } from '../showroom-search-text-input/showroom-search-text-input.component';

export type UniqueSellingPointCtaProps = {
	searchOnClick: (input: string) => void;
};

export const UniqueSellingPointCta: FunctionComponent<UniqueSellingPointCtaProps> = ({ searchOnClick }) => {
	return (
		<section>
			<h2 className="theme-grey-darker omni-theme-primary pa0 ma0  f3 f2-ns fw2 lh-title lh-copy-ns">
				Ferguson Home showrooms offer <b>personalized consultations</b> and a <b>curated selection</b> of products by the brands you
				love along with <b>unparalleled project support.</b>
			</h2>
			<div className="theme-accent bw2 bb mv3 mv4-ns" style={{ width: 150 }} />
			<div className="f5 f4-ns fw4 lh-copy lh-title-ns mb2 mb3-ns">{UNIQUE_SELLING_POINT_INPUT_LABEL}</div>

			<ShowroomSearchTextInput searchOnClick={searchOnClick} />
		</section>
	);
};
