import { type FunctionComponent } from 'react';
import { MEDIUM_MAX_PX, SMALL_MAX_PX } from '../../../../constants/breakpoints';
import {
	SHOWROOM_EXPEIRENCE_CARD_DATA,
	SHOWROOM_EXPERIENCE_DESCRIPTION,
	SHOWROOM_EXPERIENCE_TITLE,
	SHOWROOM_EXPIERENCE_PREPARE_SECTION_DATA,
	SHOWROOM_EXPIERENCE_PREPARE_SECTION_DESCRIPTION,
	SHOWROOM_EXPIERENCE_PREPARE_SECTION_TITLE,
	SHOWROOM_MARBLE_BACKGROUND_PUBLIC_ID
} from '../../../../constants/showroom';
import { type Variation } from '../../../../helpers/images/images.helper';
import { CloudinaryImage } from '../../../images/cloudinary-image/cloudinary-image.component';
import { ShowroomMeetExpert } from '../../showroom-meet-expert/showroom-meet-expert.component';
import { ShowroomExperienceContentCard } from '../showroom-experience-content-card/showroom-experience-content-card.component';
import { ShowroomExperienceContentCompactCard } from '../showroom-experience-content-compact-card/showroom-experience-content-compact-card.component';

const ShowroomExperienceBackground: FunctionComponent = () => {
	const variations: Variation[] = [
		{ mediaCondition: { maxWidth: SMALL_MAX_PX }, options: { width: 430, height: 2074, crop: 'fill' } },
		{ mediaCondition: { maxWidth: MEDIUM_MAX_PX }, options: { width: 768, height: 2074, crop: 'fill' } }
	];
	return (
		<div className="absolute bottom-0 w-100 h-100">
			<div
				className="absolute top-0 w-100 h-100"
				style={{
					backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
					zIndex: 1
				}}
			/>
			<CloudinaryImage
				publicID={SHOWROOM_MARBLE_BACKGROUND_PUBLIC_ID}
				options={{ width: 4000, height: 2074, crop: 'fill' }}
				description="showroom experience background"
				className="absolute bottom-0 h-100"
				useDimensions={false}
				variations={variations}
			/>
		</div>
	);
};

export type ShowroomExperienceContainerProps = {
	searchOnClick: (input: string) => void;
};

export const ShowroomExperienceContainer: FunctionComponent<ShowroomExperienceContainerProps> = ({ searchOnClick }) => {
	return (
		<section className="relative flex flex-column items-center pb3 pb6-ns pt3 pt0-ns h-100 w-100">
			<div className="flex flex-column gr4 ph3 ph0-l z-2" style={{ maxWidth: 1280 }}>
				<div className="flex flex-column gr4">
					<div className="flex flex-column gr1" style={{ maxWidth: 750 }}>
						<h2 className="ma0 pa0 f3 f2-ns lh-title b theme-grey-darker">{SHOWROOM_EXPERIENCE_TITLE}</h2>
						<p className="ma0 pa0 f6 f5-ns lh-copy fw4 theme-grey-dark">{SHOWROOM_EXPERIENCE_DESCRIPTION}</p>
					</div>
					<div className="dn flex-ns flex-column gr4">
						{SHOWROOM_EXPEIRENCE_CARD_DATA.map((card) => (
							<ShowroomExperienceContentCard key={card.title} {...card} />
						))}
					</div>
					<div className="flex dn-ns flex-column gr3">
						{SHOWROOM_EXPEIRENCE_CARD_DATA.map((card) => (
							<ShowroomExperienceContentCompactCard key={card.title} {...card} />
						))}
					</div>
				</div>
				<div className="flex flex-column gr4">
					<div className="flex flex-column gr1" style={{ maxWidth: 750 }}>
						<h2 className="ma0 pa0 f3 f2-ns lh-title b theme-grey-darker">{SHOWROOM_EXPIERENCE_PREPARE_SECTION_TITLE}</h2>
						<p className="ma0 pa0 f6 f5-ns lh-copy fw4 theme-grey-dark">{SHOWROOM_EXPIERENCE_PREPARE_SECTION_DESCRIPTION}</p>
					</div>
					<div className="flex flex-row ga3 flex-wrap justify-center-m">
						{SHOWROOM_EXPIERENCE_PREPARE_SECTION_DATA.map((card) => (
							<ShowroomExperienceContentCompactCard key={card.title} {...card} />
						))}
					</div>
				</div>
				<div className="mt6">
					<ShowroomMeetExpert searchOnClick={searchOnClick} />
				</div>
			</div>
			<ShowroomExperienceBackground />
		</section>
	);
};
