import { type LocationTypeEnum } from '../../__generated__/graphql-client-types';

export const PLUMBING_LOCATION_BASE_URL = 'https://www.ferguson.com/branch/';

export const READABLE_LOCATION_TYPES: { [K in LocationTypeEnum]: string } = {
	APPLIANCE: 'Showroom',
	FACTORY_DIRECT: 'Showroom',
	FIRE_AND_FABRICATION: 'Fire & Fabrication',
	HVAC: 'HVAC',
	LIGHTING_GALLERY: 'Showroom',
	MECHANICAL_INDUSTRIAL: 'Mechanical/Industrial',
	PLUMBING: 'Plumbing',
	SELECTION_CENTER: 'Selection Center',
	SHOWROOM: 'Showroom',
	STUDIO: 'Studio',
	WATERWORKS: 'Waterworks'
};

export const SUPPORTED_LOCATION_TYPES: LocationTypeEnum[] = ['SHOWROOM', 'SELECTION_CENTER', 'STUDIO'];

export const PLUMBING_LOCATION_PARAGRAPH =
	'This showroom is co-located with a Ferguson plumbing sales counter, offering residential and commercial plumbing products. Sales counter hours and contact information may differ.';
