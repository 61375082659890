import { useQuery } from '@apollo/client';
import { type FunctionComponent } from 'react';
import { type ShowroomsByLocationQuery, type ShowroomsByLocationQueryVariables } from '../../../__generated__/graphql-client-types';
import { makeShowroomLocationLink } from '../../../helpers/showroom/showroom.helper';
import { useGoogleMapsGeocoder } from '../../../hooks/google/google-maps.hooks';
import { useSortedShowroomLocations } from '../../../hooks/showroom/sorted-showroom-locations/sorted-showroom-locations.hooks';
import { SHOWROOMS_BY_LOCATION } from '../../../queries/showroom/showroom.queries';
import { type ShowroomLocation } from '../../../types/showroom.types';
import { HelpIcon } from '../../common-components/help-icon/help-icon.component';
import { StyledLink } from '../../common-components/link/styled-link.component';

type ShowroomsNearMeCardProps = {
	showroomLocation: ShowroomLocation;
};

const ShowroomsNearMeCard: FunctionComponent<ShowroomsNearMeCardProps> = ({ showroomLocation }) => {
	const isSelectionCenterOnly = showroomLocation.locationTypes.includes('SELECTION_CENTER');
	const address = showroomLocation.address;
	// only showing the 5 digit zip
	const formattedZip = address.zipCode.slice(0, 5);
	return (
		<div
			className="shadow-1 w-100 w-30-m w-33-l flex flex-column justify-between ba b--theme-grey-lighter br2 pa3"
			data-testid="showroom-location-card">
			<div className="b f4-ns f5 lh-title nowrap mr7">
				{address.city}, {address.state}
			</div>
			<div className="f5-ns f6 lh-copy flex flex-column mb5">
				<span>{address.addressLine1}</span>
				<span>
					{address.city}, {address.state} {formattedZip}
				</span>
			</div>
			<span className="f5 lh-copy flex">
				<StyledLink url={makeShowroomLocationLink(showroomLocation.branchPageUrl)}>
					{!isSelectionCenterOnly ? 'View Showroom' : 'View Selection Center'}
				</StyledLink>
				{isSelectionCenterOnly ? (
					<HelpIcon iconClassName="pa1">
						Our selection centers offer a limited product line but all of our product expertise
					</HelpIcon>
				) : null}
			</span>
		</div>
	);
};

export type ShowroomsNearMeProps = {
	customerLocation: string;
	showroomsByLocation: ShowroomLocation[];
};

export const ShowroomsNearMe: FunctionComponent<ShowroomsNearMeProps> = ({ customerLocation, showroomsByLocation }) => {
	const showroomLocationsToRender = showroomsByLocation.slice(0, 3);
	return (
		<div className="ph3 ph0-l">
			<div className="f3 f2-ns lh-title b theme-grey-darker mb4">Showrooms Near {customerLocation}</div>
			<div className="flex flex-column flex-row-ns gc5 gr3">
				{showroomLocationsToRender.map((showroom) => {
					return <ShowroomsNearMeCard showroomLocation={showroom} key={showroom.id} />;
				})}
			</div>
		</div>
	);
};

export type ShowroomsNearMeContainerProps = {
	zipcode: string;
	city: string;
};
export const ShowroomsNearMeContainer: FunctionComponent<ShowroomsNearMeContainerProps> = ({ zipcode, city }) => {
	const { data, loading } = useQuery<ShowroomsByLocationQuery, ShowroomsByLocationQueryVariables>(SHOWROOMS_BY_LOCATION, {
		variables: {
			input: {
				zip: zipcode
			}
		}
	});

	// TODO: EFDC-23671 - identical logic on showroom search results page, could be consolidated
	const showroomsByLocation = data?.showroomsByLocation;

	// Get the {lat,lon} position for the current zipCode.
	const { loading: centerMarkerLoading, marker: centerMarker } = useGoogleMapsGeocoder(`${zipcode},US`);
	// Sort the returned locations by distance from search center (the searched zip code) since they are not sorted
	// coming from the api. Use the unsorted locations list as a backup if the center coordinates could not be calculated.
	const sortedShowroomLocations = useSortedShowroomLocations(centerMarker, showroomsByLocation);
	const locationsToRender = !centerMarkerLoading && !sortedShowroomLocations ? showroomsByLocation : sortedShowroomLocations;

	if (!locationsToRender && !loading) {
		return null;
	}

	return locationsToRender && <ShowroomsNearMe customerLocation={city} showroomsByLocation={locationsToRender} />;
};
