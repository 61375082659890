import { type Libraries, Loader } from '@googlemaps/js-api-loader';
import { logError } from 'fergy-core-react-logging';
import { useEffect, useMemo, useState } from 'react';
import { GEOCODER_ERROR } from '../../constants/google-maps/message';
import { type LocationMarker } from '../../types/google-maps.types';
import { useSiteInfo } from '../apollo/site/site.hooks';

// Google Maps and its desired libraries can only be loaded once in the browser using the {@link useGoogleMaps} hook,
// so this list must include all libraries used anywhere in the client code.
const GOOGLE_MAPS_LIBRARIES: Libraries = ['places', 'geometry', 'marker'];

export type UseGoogleMapsResult = {
	error?: Error | undefined;
	googleApi?: typeof google | undefined;
};

/**
 * This hook will load the Google Maps api with the configured options and libraries. This hook may be used multiple
 * times in the application; the Google Maps API will still only load once.
 *
 * The list of libraries to load is coded as a constant because the Loader doesn't support incrementally loading
 * new libraries. This means that all Google Maps libraries used anywhere in the client should be added to
 * the {@link GOOGLE_MAPS_LIBRARIES} list.
 */
export const useGoogleMaps = (): UseGoogleMapsResult => {
	const { loading, data: siteInfoData } = useSiteInfo();

	const [result, setResult] = useState<UseGoogleMapsResult>({});
	useEffect(() => {
		if (!loading) {
			const apiKey = siteInfoData?.dataLayer.keys.googlePlacesApi;
			const loader = new Loader({ apiKey, libraries: GOOGLE_MAPS_LIBRARIES });
			loader
				.load()
				.then((googleApi) => setResult({ googleApi }))
				.catch((error) => setResult({ error }));
		}
	}, [loading, siteInfoData]);

	return result;
};

export type LocationMarkerResult = {
	loading: boolean;
	marker: LocationMarker | undefined;
};

/**
 * Use the Google Maps Geocoder library to look up the latitude and longitude for the given address.
 * This first loads the API and Geocoder library if they're not loaded already.
 * Loading or location lookup errors are logged.
 */
export const useGoogleMapsGeocoder = (searchAddress: string | undefined): LocationMarkerResult => {
	const { googleApi, error } = useGoogleMaps();

	const geocoder = useMemo(() => {
		return googleApi ? new googleApi.maps.Geocoder() : undefined;
	}, [googleApi]);

	const [markerResult, setMarkerResult] = useState<LocationMarkerResult>({ loading: true, marker: undefined });

	useEffect(() => {
		if (error) {
			logError(error, { message: GEOCODER_ERROR });
			setMarkerResult({ loading: false, marker: undefined });
		}
	}, [error]);

	useEffect(() => {
		if (geocoder) {
			if (searchAddress) {
				geocoder
					.geocode({ address: `${searchAddress},US` })
					.then((response: google.maps.GeocoderResponse) => {
						const location = response.results[0].geometry.location;
						setMarkerResult({
							loading: false,
							marker: {
								latitude: location.lat(),
								longitude: location.lng()
							}
						});
					})
					.catch((err) => {
						logError(err, { message: GEOCODER_ERROR });
						setMarkerResult({ loading: false, marker: undefined });
					});
			} else {
				setMarkerResult({ loading: false, marker: undefined });
			}
		}
	}, [geocoder, searchAddress]);

	return markerResult;
};
