import { type FunctionComponent } from 'react';
import { MEDIUM_MAX_PX } from '../../../../constants/breakpoints';
import { type Variation } from '../../../../helpers/images/images.helper';
import { CloudinaryImage } from '../../../images/cloudinary-image/cloudinary-image.component';

type ContentCardDetailsProps = { title: string; description: string };

const ContentCardDetails: FunctionComponent<ContentCardDetailsProps> = ({ title, description }) => {
	return (
		<div className="pa0 pa4-ns bg-theme-white br2 flex flex-column justify-center self-stretch">
			<h2 className="ma0 pa0 f3 lh-title b theme-grey-darker">{title}</h2>
			<div className="theme-accent bw1 bb mv2" style={{ width: 150 }} />
			<p className="ma0 pa0 f5 lh-copy fw4 theme-grey-dark">{description}</p>
		</div>
	);
};

export type ShowroomExperienceContentCardProps = {
	title: string;
	description: string;
	imageId: string;
	imagePosition: 'LEFT' | 'RIGHT';
};

export const ShowroomExperienceContentCard: FunctionComponent<ShowroomExperienceContentCardProps> = ({
	title,
	description,
	imageId,
	imagePosition
}) => {
	const variations: Variation[] = [{ mediaCondition: { maxWidth: MEDIUM_MAX_PX }, options: { width: 420, height: 350, crop: 'fill' } }];
	return (
		<div className="flex flex-column flex-row-ns gc4 items-center">
			<CloudinaryImage
				publicID={imageId}
				options={{ crop: 'fit' }}
				description="showroom experience"
				className="db dn-ns br2 shadow-1 mb3 w-100"
			/>
			{imagePosition === 'LEFT' && (
				<CloudinaryImage
					publicID={imageId}
					options={{ width: 720, height: 350, crop: 'fill' }}
					description="showroom experience left"
					className="dn db-ns br2 shadow-1"
					variations={variations}
				/>
			)}
			<ContentCardDetails title={title} description={description} />
			{imagePosition === 'RIGHT' && (
				<CloudinaryImage
					publicID={imageId}
					options={{ width: 720, height: 350, crop: 'fill' }}
					description="showroom experience right"
					className="dn db-ns br2 shadow-1"
					variations={variations}
				/>
			)}
		</div>
	);
};
